import React from 'react';

const SkillsAnimation = () => {
  return (
    <div>

    </div>
  )
};

export default SkillsAnimation;
